$(document).ready(function() {

  const $thankYou = $('body.thank-you');
  const $thankYouH1 = $thankYou.find('[data-ty-id="h1"]');
  const $thankYouP = $thankYou.find('[data-ty-id="p"]');
  const $successIcon = $('.success-icon');

  const actions = {

    book_appt: {

      showWidget(type, acuityParam) {
        const widgetSrc = $('.acuity-widget').attr('src');
        return $('.acuity-widget').attr('src', widgetSrc + '&' + type + '=' + acuityParam);
      }
    },

    thank_you: {

      showApply() {
        $thankYouH1.text('Thank you for applying.');
        return $thankYouP.text('We will review your application shortly.');
      },

      showConfirmation() {
        $thankYouH1.text('Form submitted successfully.');
        $thankYouP.remove();
        return $successIcon.css('display', 'flex');
      },

      showSubscribe() {
        $thankYouH1.text('You have been successfully subscribed.');
        $thankYouP.remove();
        return $successIcon.css('display', 'flex');
      },

      showWidget(type, acuityParam) {
        return $('#schedule-consultation iframe').attr('src', 'https://app.acuityscheduling.com/schedule.php?owner=11249458&' + type + '=' + acuityParam);
      }
    }
  };

  // Customize Thank You page based off of URL parameters
  // 5593981 -> Anthony
  // 1482579 -> Steven
  if ($('body.thank-you').length) {
    if (getURLParam('application') === 'true') {
      return actions.thank_you.showApply();
    } else if (getURLParam('subscription') === 'true') {
      return actions.thank_you.showSubscribe();
    } else if (getURLParam('appointment_type') !== undefined) {
      return actions.thank_you.showWidget('appointmentType', getURLParam('appointment_type'));
    } else if (getURLParam('calendar_id') !== undefined) {
      return actions.thank_you.showWidget('calendarID', getURLParam('calendar_id'));
    } else if (getURLParam('form_submission') !== undefined) {
      return actions.thank_you.showConfirmation();
    }
  } else if ($('body.book-appointment').length) {
    if (getURLParam('appointment_type') !== undefined) {
      return actions.book_appt.showWidget('appointmentType', getURLParam('appointment_type'));
    } else if (getURLParam('calendar_id') !== undefined) {
      return actions.book_appt.showWidget('calendarID', getURLParam('calendar_id'));
    }
  }
});
