import PerlinNoise from './vendor/perlin_noise';
import FloatingLogosConfig from './floating_logos_config';

export default class FloatingLogos {

  constructor(id) {
    this.step = this.step.bind(this);
    this.bubbles = FloatingLogosConfig.bubbles;
    this.logos = shuffle(FloatingLogosConfig.logos);
    if (!(this.logos.length > (this.bubbles.length + 1))) {
      console.error('Logos array must have at least one more item then bubbles');
      return;
    }
    this.containerHeight = FloatingLogosConfig.containerHeight;
    this.containerWidth = FloatingLogosConfig.containerWidth;
    this.introDelay = FloatingLogosConfig.introDelay;
    this.introDuration = FloatingLogosConfig.introDuration;
    this.bubbleContainer = document.querySelector(
      FloatingLogosConfig.bubbleContainer
    );
    this.maxShrink = FloatingLogosConfig.maxShrink;
    this.noiseScale = FloatingLogosConfig.noiseScale;
    this.noiseSpeed = FloatingLogosConfig.noiseSpeed;
    this.scrollSpeed = FloatingLogosConfig.scrollSpeed;

    this.noiseT = 0;
    this.scrollX = 0;
    this.firstTick = null;
    this.lastTick = 0;
    this.vertShink = 0;
    this.perlin = new PerlinNoise();

    this.verticalShrink();
    this.initBubbles();

    // start animation
    this.step(0);

    // adjust vertical shrink vased on window height
    $(window).on('resize', () => {
      return this.verticalShrink();
    });
  }

  step(tick) {
    if (!this.firstTick) { this.firstTick = tick; }
    tick -= this.firstTick;
    this.tickLength = tick - this.lastTick;
    this.lastTick = tick;
    this.scrollX -= this.tickLength * this.scrollSpeed;
    this.noiseT += this.tickLength * this.noiseSpeed;
    for (let i = 0; i < this.bubbles.length; i++) {
      var b = this.bubbles[i];
      b.noiseX = (this.perlin.getVal(b.seedX + this.noiseT) * this.noiseScale) - (this.noiseScale / 2);
      b.noiseY = (this.perlin.getVal(b.seedY + this.noiseT) * this.noiseScale) - (this.noiseScale / 2);
      if ((tick > b.introDelay) && (b.introProgress < 1)) {
        var progress = b.introProgress + (this.tickLength / this.introDuration);
        b.introProgress = Math.min(1, progress);
      }
      this.updateBubble(b);
    }

    return window.requestAnimationFrame(this.step);
  }

  initBubbles() {
    return (() => {
      const result = [];
      for (let i = 0; i < this.bubbles.length; i++) {
        var b = this.bubbles[i];
        b.seedX = 1e4 * Math.random();
        b.seedY = 1e4 * Math.random();
        b.noiseX = (b.noiseY = 0);
        b.introDelay = Math.random() * this.introDelay;
        b.introProgress = 0;
        if (!b.el) { b.el = this.createBubbleEl(); }
        this.updateBubble(b);
        var logo = this.randomLogo();
        result.push(this.setLogo(b.el, logo));
      }
      return result;
    })();
  }

  introProgressFunc(e) {
    if (e < .5) { return 2 * e * e; } else { return ((4 - (2 * e)) * e) - 1; }
  }

  updateBubble(b) {
    const x = b.x + b.noiseX + this.scrollX;
    let y = b.y + b.noiseY;
    y = this.adjustForWindowHeight(y);
    if (x < -200) {
      b.x += this.containerWidth;
      const newLogo = this.randomLogo(this.getLogo(b.el));
      this.setLogo(b.el, newLogo);
    }

    let s = b.s || 1;
    s *= (this.introProgressFunc(b.introProgress) / 20) + .95;
    b.el.style.opacity = this.introProgressFunc(b.introProgress);
    return b.el.style.transform = `translate(${x}px, ${y}px) scale(${s})`;
  }

  adjustForWindowHeight(y) {
    const shrink = this.vertShrink * this.maxShrink;
    return (y * (1 - shrink)) + ((this.containerHeight / 2) * shrink);
  }

  verticalShrink() {
    this.vertShrink = this.calcVertShrink(1e3, 800, window.innerHeight);
    return this.vertShrink = this.minMax(this.vertShrink, 0, 1);
  }

  calcVertShrink(e, n, windowHeight) {
    return (windowHeight - e) / (n - e);
  }

  minMax(a, min, max) {
    return Math.max(Math.min(a, max), min);
  }

  createBubbleEl() {
    const el = document.createElement('div');
    el.classList.add('floating-logo');
    el.appendChild(document.createElement('span'));
    this.bubbleContainer.appendChild(el);
    return el;
  }

  // set the logo on the given element
  setLogo(el, logo) {
    const existingClass = el.getAttribute('data-logo');
    if (existingClass) { el.classList.remove(existingClass); }
    el.classList.add(logo.cssClass);
    el.setAttribute('data-logo', logo.cssClass);
    return el.childNodes[0].textContent = logo.tooltip;
  }

  // extract the logo info from the given element
  getLogo(el) {
    return {
      cssClass: el.getAttribute('data-logo'),
      tooltip: el.childNodes[0].textContent
    };
  }

  // get a random logo from the list. if a logo is given it returns that one
  // to the list this requires the list of logos be at least one larger than
  // the number of bubbles
  randomLogo(logo) {
    if (logo == null) { logo = undefined; }
    if (logo !== undefined) {
      this.logos.push({
        cssClass: logo.cssClass,
        tooltip: logo.tooltip
      });
    }
    return this.logos.shift();
  }
}
