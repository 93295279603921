import Cookies from 'js-cookie';

$(document).ready(function() {

  // Variables
  const $document = $(document);
  const $window = $(window);
  const $html_body = $('html, body');
  const $body = $('body');
  const $overlayRoot = $('.overlay-content-root');
  const $overlayContent = $overlayRoot.find('.overlay-content');
  const $overlay = $overlayRoot.find('.content-overlay-backdrop');
  const $modal = $overlayRoot.find('.modal');
  const $closeModalBtn = $modal.find('.close-modal-button');
  const $overlayTrigger = $('.overlay-content-trigger');

  // ============================================================================
  // ANIMATE SCROLL
  // ============================================================================

  const $link = $('a');
  $link.on('click', function() {
    return animateScroll($(this));
  });

  // ============================================================================
  // BLOG POST TAG LINKS
  // ============================================================================

  $('.article-box').on('click', function(e) {
    const url = $(e.target).data('url');
    if (url !== undefined) {
      e.preventDefault();
      return window.location = url;
    }
  });

  // ============================================================================
  // DETECT IF USER IS ON AN IOS DEVICE
  // ============================================================================

  if (navigator.userAgent.match(/(iPod|iPhone|iPad)/i)) {
    $body.addClass('is-ios');
  }

  // ============================================================================
  // CSS TRANSITION HANDLING
  // ============================================================================

  setTimeout(() => $body.removeClass('preload')
  , 500);

  $window.on('resize', function() {
    let resizeTimer;
    $body.addClass('preload');
    clearTimeout(resizeTimer);
    return resizeTimer = setTimeout((() => $body.removeClass('preload')), 250);
  });

  // ============================================================================
  // JOB OPENINGS
  // ============================================================================

  const $jobTrigger = $('.job-title-bar').on('click', function(e) {
    console.log($(e.target));
    if (!$(e.target).is('.apply-button')) {
      $(this).find('.button-background').addClass('click').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
        return $(this).removeClass('click');
      });
      $(this).next().toggle();
      return $(this).find('.job-expand-button').toggleClass('active');
    }
  });

  // ============================================================================
  // BLOG FILTER
  // ============================================================================

  const $blogFilter = $('.blog-filter');
  const $blogFilterToggle = $('.blog-filter-list-toggle').on('click', function() {
    const $this = $(this);
    const $parent = $this.closest('.blog-filter').removeClass('inactive').toggleClass('active');
    if (!$parent.hasClass('active')) {
      return $blogFilter.not($parent).removeClass('inactive');
    } else {
      return $blogFilter.not($parent).removeClass('active').addClass('inactive');
    }
  });

  $document.on('click', function(e) {
    if (!$(e.target).closest('.blog-filter').length > 0) {
      return $blogFilter.removeClass('active inactive');
    }
  });

  // ============================================================================
  // ADD EXTRA MARGIN TO P ELEMENTS THAT CONTAIN AN IMG ON BLOG POST PAGE
  // ============================================================================

  const $imgP = $('body.blog-post article img, body.blog-post article iframe').parent();
  if ($imgP.prev().length) {
    $imgP.css('margin-top', '60px');
  }
  if ($imgP.next().length) {
    $imgP.next().css('margin-top', '60px');
  }
  $imgP.css('font-size', '0.875rem');

  // ============================================================================
  // FILL "POSITION YOU ARE APPLYING FOR" INPUT WHEN USER CLICKS "APPLY"
  // ============================================================================

  $('.apply-button').on('click', function() {
    localStorage.removeItem('applicationPosition');
    const position = $(this).closest('.job-opening').find('.job-title').text();
    return localStorage.setItem('applicationPosition', position);
  });

  const $positionInput = $('[fs-field-validation-name="Position you are applying for"]').find('input');
  if ($positionInput.length && (localStorage.getItem('applicationPosition') !== null)) {
    const inputText = localStorage.getItem('applicationPosition');
    $positionInput.val(inputText);
  }

  // ============================================================================
  // COLLECT URL OF PAGE WHEN USER SUBSCRIBES TO BLOG
  // ============================================================================

  $window.on('load', function() {
    if ($('body.blog-post').length) {
      const url = window.location.href;
      return setTimeout(() => $('[fs-field-validation-name="Current Page"]').find('input').val(url)
      , 500);
    }
  });

  // ============================================================================
  // LEAD SOURCE, TRIAL LENGTH AND COUPON
  // ============================================================================

  return (function() {
    const lsParam = getURLParam('ls');
    if (lsParam) { Cookies.set('lead_source', lsParam, { domain: '.bookkeeper360.com' }); }

    const tlParam = getURLParam('tl');
    if (tlParam) { Cookies.set('trial_length', tlParam, { domain: '.bookkeeper360.com' }); }

    const couponParam = getURLParam('coupon');
    if (couponParam) { return Cookies.set('coupon', couponParam, { domain: '.bookkeeper360.com' }); }
  })();
});
