import { Controller } from '@hotwired/stimulus';
import anime from 'animejs/lib/anime.es.js';

export default (class extends Controller {
  static targets = ['card'];

  connect() {
    this.hasRun = false;
    this.initIfVisible();
  }

  initAnimation() {
    anime({
      targets: this.cardTargets[0],
      duration: 1000,
      easing: 'cubicBezier(.25,0,0,1)',
      translateX: ['5%', 0],
      opacity: [0, 1]
    })

    anime({
      targets: this.cardTargets[1],
      duration: 1000,
      delay: 300,
      easing: 'cubicBezier(.25,0,0,1)',
      translateX: ['-10%', 0],
      opacity: [0, 1]
    })

    this.hasRun = true;
  }

  initIfVisible() {
    if (!isElementVisible(this.element, 0) && this.hasRun) {
      this.resetPosition();
      return this.hasRun = false;
    } else if (isElementVisible(this.element, 250) && !this.hasRun) {
      this.initAnimation();
    } else {
      return;
    }
  }

  resetPosition() {
    this.cardTargets[0].style.cssText =
      "transform: translateX(5%); opacity: 0;";

    this.cardTargets[1].style.cssText =
      "transform: translateX(-10%); opacity: 0;";
  }
});
