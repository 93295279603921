window.shuffle = function(source) {
  if (!(source.length >= 2)) { return source; }
  for (let start = source.length-1, index = start, asc = start <= 1; asc ? index <= 1 : index >= 1; asc ? index++ : index--) {
    var randomIndex = Math.floor(Math.random() * (index + 1));
    [source[index], source[randomIndex]] = Array.from([source[randomIndex], source[index]]);
  }
  return source;
};

window.getURLParam = function(sParam) {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split('&');
  let sParameterName = undefined;
  let i = undefined;
  i = 0;
  while (i < sURLVariables.length) {
    sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      if (sParameterName[1] === undefined) { return true; } else { return decodeURIComponent(sParameterName[1]); }
    }
    i++;
  }
};

// Animate scroll
window.animateScroll = function(el) {
  const href = el.attr('href');
  if ((href.indexOf('#') > -1) && $(href).length) {
    const targetY = $(href).offset().top - $('#header-menu').outerHeight();
    return $('html, body').animate({ scrollTop: targetY }, 1000, 'easeInOutCubic');
  }
};

window.isElementVisible = (elm, threshold, mode) => {
  threshold = threshold || 0;
  mode = mode || 'visible';

  const rect = elm.getBoundingClientRect();
  const viewHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight
  );
  const above = rect.bottom - threshold < 0;
  const below = rect.top - viewHeight + threshold >= 0;

  if (mode === 'above') {
    return above;
  } else if (mode === 'below') {
    return below;
  } else {
    return !above && !below;
  }
};
