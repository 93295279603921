import { Controller } from '@hotwired/stimulus';

export default (class extends Controller {
  static values = {
    animated: Boolean
  }

  connect() {
    this.animateIfVisible();
  }

  animateIn() {
    this.animatedValue = true;
  }

  resetPosition() {
    this.animatedValue = false;
  }

  animateIfVisible() {
    if (!isElementVisible(this.element, 0) && this.animatedValue) {
      return this.resetPosition();
    } else if (isElementVisible(this.element, 250) && !this.animatedValue) {
      return this.animateIn();
    } else {
      return;
    }
  }
});
