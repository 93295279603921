import 'iframe-resizer';
import './utilities';
import './easing';
import './form-action-manager';
import './team-modals';
import './site';
import './init';

import 'cleave.js/dist/cleave-esm.min.js';

import { Application } from '@hotwired/stimulus';
import { definitions } from 'stimulus:./controllers';

window.Stimulus = Application.start();
Stimulus.load(definitions);
