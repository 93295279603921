import { Controller } from '@hotwired/stimulus';
import anime from 'animejs/lib/anime.es.js';

export default (class extends Controller {
  static targets = ['card'];

  connect() {
    this.hasRun = false;
    this.visibleCardIndex = 0;
    if (!isElementVisible(this.element, 150) || this.hasRun) return;

    this.initAnimation();
  }

  initAnimation() {
    setInterval(() => {
      let indexToShow = this.visibleCardIndex === 1 ? 1 : 0;
      let indexToHide = this.visibleCardIndex === 1 ? 0 : 1;

      let tl = anime.timeline({
        easing: 'easeInOutQuad'
      });

      tl.add({
        targets: this.cardTargets[indexToHide],
        duration: 500,
        scale: 1.04,
        translateY: [0, '-5%'],
        opacity: 0
      })
      .add({
        targets: this.cardTargets[indexToShow],
        duration: 600,
        scale: 1,
        boxShadow: [
          '0 9px 14px -4px rgba(50, 50, 93, 0.22), 0 3px 6px -3px rgba(0, 0, 0, 0.26)',
          '0 18px 28px -8px rgba(50, 50, 93, 0.3), 0 6px 12px -6px rgba(0, 0, 0, 0.34)'
        ],
        translateY: 0,
        complete: () => {
          this.cardTargets[indexToShow].style.zIndex = '1';
          this.cardTargets[indexToHide].style.cssText = 'z-index: 0; transform: translateY(35%) scale(0.85); opacity: 0; box-shadow: 0 9px 14px -4px rgba(50, 50, 93, 0.22), 0 3px 6px -3px rgba(0, 0, 0, 0.26);';
        }
      }, '-=450')
      .add({
        targets: this.cardTargets[indexToHide],
        duration: 500,
        scale: [0.85, 0.9],
        translateY: ['35%', '25%'],
        opacity: 1
      }, '-=50');

      this.visibleCardIndex = this.visibleCardIndex + 1 === 2 ? 0 : 1;
    }, 4000);

    this.hasRun = true;
  }

  initIfVisible() {
    if (!isElementVisible(this.element, 150) || this.hasRun) return;

    this.initAnimation();
  }
});
