import { Controller } from '@hotwired/stimulus';

export default (class extends Controller {
  connect() {
    this.toggleScrolled();
  }

  close(event) {
    if ((event.type === 'resize') && ($(window).width() >= 1160)) { return; }
    if ((event.type === 'click') && $(event.target).closest(this.element).length) { return; }

    $(this.element).removeClass('active');
  }

  toggleMobileMenu() {
    $(this.element).toggleClass('active');
  }

  toggleScrolled() {
    if ($(window).scrollTop() > 0) {
      $(this.element).addClass('scrolled');
    } else {
      $(this.element).removeClass('scrolled');
    }
  }
});
