import { Controller } from '@hotwired/stimulus';
import { CountUp } from 'countup.js';

export default (class extends Controller {
  static targets = ['number'];
  static values = { end: Number };

  connect() {
    this.hasRun = false;
    this.id = this.numberTarget.getAttribute('id');
    this.options = {
      startVal: 0,
      duration: 3,
      decimalPlaces: 1,
      suffix: this.data.get('suffix') || ''
    };

    if (!isElementVisible(this.element, 200)) return;

    this.initCountUp();
  }

  initCountUpIfVisible() {
    if (!isElementVisible(this.element, 0) && this.hasRun) {
      return this.hasRun = false;
    } else if (isElementVisible(this.element, 200) && !this.hasRun) {
      this.initCountUp();
    } else {
      return;
    }
  }

  initCountUp() {
    const countUp = new CountUp(this.id, this.endValue, this.options);

    if (!countUp.error) {
      countUp.start();
      this.hasRun = true;
    } else {
      console.error(countUp.error);
    }
  }
});
