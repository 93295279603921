import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['nav', 'banner'];

  connect() {
    this.displaceContentBelowBanner();
    this.handleRedirects();
  }

  displaceContentBelowBanner() {
    if (!this.hasBannerTarget) { return; }

    const bannerHeight = $(this.bannerTarget).outerHeight();

    $('body > header').css('margin-top', `${bannerHeight}px`);
    $(this.navTarget).css('top', `${bannerHeight}px`);
  }

  handleRedirects() {
    if (getURLParam('ls') === 'fitsmallbusiness') {
      window.location.href = 'https://partner.bookkeeper360.com/fit-small-business';
    }
  }
}
