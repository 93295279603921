import { Controller } from '@hotwired/stimulus';

export default (class extends Controller {
  static targets = ['card'];

  connect() {
    this.hasRun = false;
    this.animateIfVisible();
  }

  animateCards() {
    Array.from(this.cardTargets).forEach(card => {
      card.style.cssText = 'transform: none; opacity: 1;';
    });

    this.hasRun = true;
  }

  animateIfVisible() {
    if (window.innerWidth < 1024) return;

    if (!isElementVisible(this.element, 0) && this.hasRun) {
      this.resetPosition();
      return this.hasRun = false;
    } else if (isElementVisible(this.element, 300) && !this.hasRun) {
      return this.animateCards();
    } else {
      return;
    }
  }

  resetPosition() {
    this.cardTargets[0].style.cssText =
      'transform: translateX(calc(50% + rem(10)))';

    this.cardTargets[1].style.cssText =
      'transform: translateX(calc(50% + rem(10))) scale(0.5)';
  }
});
