import { Controller } from '@hotwired/stimulus';
import numeral from 'numeral';

export default class extends Controller {
  static targets = ['wages', 'contractors', 'savings'];

  connect() {
    this.formstackWagesInput = $('[fs-field-validation-name="Wages"] input[type="number"]');
    this.formstackContractorsInput = $('[fs-field-validation-name="Contractors"] input[type="number"]');

    new Cleave($(this.wagesTarget), {numeral: true});
    new Cleave($(this.contractorsTarget), {numeral: true});

    this.calculate();
  }

  calculate() {
    let wages = this.sanitizeInput(this.wagesTarget.value);
    if (wages > 250000) { wages = 250000; }
    let contractorsValue = this.sanitizeInput(this.contractorsTarget.value);
    if (contractorsValue > 250000) { contractorsValue = 250000; }
    const contractors = contractorsValue * 0.65;
    const totalRDExpenses = wages + contractors;
    const wagesSavings = wages * 0.1;
    const contractorSavings = contractors * 0.1;
    const totalSavings = wagesSavings + contractorSavings;
    this.savingsTarget.textContent = this.formatValue(totalSavings);
  }

  disableTab(event) {
    if (event.keyCode !== 9) { return; }
    event.preventDefault();
  }

  formatValue(value) {
    const format = (value % 1) === 0 ? '$0,0' : '$0,0.00';
    return numeral(value).format(format);
  }

  sanitizeInput(value) {
    value = value.replace(/[,]/g, '').replace(/^0+(?!\.|$)/, '');
    value = parseFloat(value);
    if (isNaN(value)) { value = 0; }
    return value;
  }

  setFormstackValues(event) {
    let wages = this.sanitizeInput(this.wagesTarget.value);
    if (wages > 250000) { wages = 250000; }
    let contractors = this.sanitizeInput(this.contractorsTarget.value);
    if (contractors > 250000) { contractors = 250000; }

    this.formstackWagesInput.val(wages);
    return this.formstackContractorsInput.val(contractors);
  }

  setMaxLimit(event) {
    const inputValue = this.sanitizeInput(event.target.value);
    if (inputValue <= 250000) { return; }

    event.target.value = numeral(250000).format('0,0');
  }
}
