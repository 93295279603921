import FloatingLogos from './floating_logos';
import tippy from 'tippy.js';
const attachFastClick = require('fastclick');

if (!window.App) { window.App = {}; }

$(document).ready(function() {

  // Remove delay between physical tap and firing of click event
  attachFastClick(document.body);

  // Variables
  const $document = $(document);
  const $window = $(window);
  const $html_body = $('html, body');
  const $body = $('body');

  const ElementQueries = require('css-element-queries/src/ElementQueries');
  ElementQueries.init();

  // Detect if user device is iOS
  navigator.userAgent.match(/(iPod|iPhone|iPad)/i) && $body.addClass('is-ios');

  // Detect if user agent is iOS Safari
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

  if (iOSSafari) {
    $body.addClass('is-ios-safari');
  }

  // Detect if user is using touch device
  const isTouchDevice = function() {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

    const mq = query => window.matchMedia(query).matches;

    if ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
      return true;
    }
    const query = [
      '(',
      prefixes.join('touch-enabled),('),
      'heartz',
      ')'
    ].join('');
    return mq(query);
  };

  if (isTouchDevice()) {
    $body.addClass('is-touch-device');
  }

  // Initialize tippy
  tippy('[data-tippy-content]', {
    animation: 'shift-toward',
    duration: 250,
    theme: 'light',
    placement: 'top'
  });

  // Resize Acuity widget
  $('.acuity-widget').iFrameResize();

  // init floating logos on technology page
  if ($('#floating-logos').length) { return new FloatingLogos('#floating-logos'); }
});
