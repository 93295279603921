import c0 from './cash_management_controller';
import c1 from './countup_controller';
import c2 from './metrics_controller';
import c3 from './nav_controller';
import c4 from './payroll_analytics_controller';
import c5 from './pricing_controller';
import c6 from './schedule_call_controller';
import c7 from './site_controller';
import c8 from './tax_credit_controller';
import c9 from './team_controller';
export const definitions = [
	{identifier: 'cash-management', controllerConstructor: c0},
	{identifier: 'countup', controllerConstructor: c1},
	{identifier: 'metrics', controllerConstructor: c2},
	{identifier: 'nav', controllerConstructor: c3},
	{identifier: 'payroll-analytics', controllerConstructor: c4},
	{identifier: 'pricing', controllerConstructor: c5},
	{identifier: 'schedule-call', controllerConstructor: c6},
	{identifier: 'site', controllerConstructor: c7},
	{identifier: 'tax-credit', controllerConstructor: c8},
	{identifier: 'team', controllerConstructor: c9},
];
