import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select', 'tab', 'member', 'photoStyleToggle'];
  static values = {
    personalPhotosVisible: Boolean,
    currentTeam: String
  };

  togglePhotoStyle(event) {
    return this.personalPhotosVisibleValue = this.photoStyleToggleTarget.checked;
  }

  toggleTeam(event) {
    const target = $(event.target);

    const team =
      target.closest(this.tabTargets).length ?
        target.closest(this.tabTargets).attr('data-team')
      :
        target.closest(this.selectTarget).val();

    // Hide/show corresponding team members
    if (team === 'all') {
      this.memberTargets.forEach(member => member.style.display = 'flex');
    } else {
      this.memberTargets.forEach(function(member) {
        if (member.getAttribute('data-team') && member.getAttribute('data-team').includes(team)) {
          return member.style.display = 'flex';
        } else {
          return member.style.display = 'none';
        }
      });
    }

    this.tabTargets.forEach(tab => tab.removeAttribute('data-active'));

    target.closest(this.tabTargets).attr('data-active', '');
    this.selectTarget.value = team;
    return this.currentTeamValue = team;
  }
}
