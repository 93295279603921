(function() {

  const $modal = $('.modal--team-member-info');
  const $photo = $modal.find('.team-member-photo');
  const $info = $modal.find('.team-member-info');
  const $name = $modal.find('h2');
  const $title = $modal.find('h3');
  const $bio = $modal.find('p');
  const $trigger = $('#team-members .overlay-content-trigger');

  return $trigger.on('click', function() {
    const photo = $(this).data('photo');
    const name = $(this).data('name');
    const title = $(this).data('title');
    const bio = $(this).find('.bio-text').text();
    let link = $(this).data('link');
    $photo.css('background-image', `url(${photo})`);
    $name.text(name);
    $title.text(title);
    $bio.text(bio);
    $modal.find('.arrow-link').remove();
    if (name === 'Nick Pasquarosa') {
      link = `\
<a href="/about-nick-pasquarosa/" class="arrow-link">
  Learn more about Nick
</a>\
`;
      return $bio.after(link);
    }
  });
})();
